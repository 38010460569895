import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import styles from './Logos.module.css'
import Marquee from 'react-fast-marquee'
import {
  Logos1,
  Logos2,
  Logos3,
  Logos4,
  Logos5,
} from '../../Assets/Icons/Logos/Logos'
import { popUpStore } from './popUpStore'
import { sendInTg } from '../../Constants'

const logosData = [
  { src: Logos1, alt: 'Takbud' },
  { src: Logos2, alt: 'Gal Zhytlobud' },
  { src: Logos3, alt: 'Avalon Inc.' },
  { src: Logos4, alt: 'Galytskyi Dvir' },
  { src: Logos5, alt: 'Family Development' },
]

const Logos = () => {
  const isPopupOpen = popUpStore((state) => state.isPopupOpen)
  const togglePopUp = popUpStore((state) => state.togglePopUp)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const togglePopup = () => {
    togglePopUp()
    if (isPopupOpen) {
      setIsSubmitted(false)
      setError(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Capture form data
    const name = e.target.name.value
    const phone = e.target.phone.value

    // Create message for sending
    const msg = { name: name, phone: phone }

    try {
      const resp = await sendInTg(msg)
      if (resp.ok) {
        setIsSubmitted(true)
        setError(false)
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <div className={'customContainer'}>
      <div className={styles.container}>
        <Marquee
          className={styles.logoWrapper}
          marqueeStyle={{ whiteSpace: 'nowrap' }}
          duration={20000}
          pauseOnHover={true}
        >
          {logosData.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className={styles.logo}
            />
          ))}
        </Marquee>

        <div className={styles.addLogo} onClick={togglePopup}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17 12H7M12 7V17"
              className={styles.addLogo_hover}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx="12"
              cy="12"
              r="11.25"
              className={styles.addLogo_hover}
              strokeWidth="1.5"
            />
          </svg>
          Тут може бути ваш логотип
        </div>

        {isPopupOpen && (
          <>
            <div className={styles.popupOverlay} onClick={togglePopup}></div>

            <div className={styles.popup}>
              <div className={styles.popupContent}>
                {!isSubmitted && !error && (
                  <>
                    <h3 className={styles.popupContent_title}>
                      Стати партнером
                    </h3>
                    <form
                      className={styles.popupContent_form}
                      onSubmit={handleSubmit}
                    >
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Імʼя"
                        required
                      />
                      <InputMask
                        mask="+38 (099) 999-99-99"
                        id="phone"
                        name="phone"
                        placeholder="Номер телефону"
                        className={styles.input}
                        required
                      />
                      <button type="submit" className={styles.submit}>
                        Надіслати заявку
                      </button>
                    </form>
                  </>
                )}

                {isSubmitted && !error && (
                  <p
                    className={`${styles.popupContent_title} ${styles.message}`}
                  >
                    Дякуємо, очікуйте на дзвінок!
                  </p>
                )}

                {error && (
                  <p
                    className={`${styles.popupContent_title} ${styles.message}`}
                  >
                    Виникла помилка надсилання. <br /> Спробуйте ще раз!
                  </p>
                )}
              </div>

              <button onClick={togglePopup} className={styles.closeBtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33325 16.0002C1.33325 7.93045 7.93021 1.3335 15.9999 1.3335C24.0696 1.3335 30.6666 7.93045 30.6666 16.0002C30.6666 24.0699 24.0696 30.6668 15.9999 30.6668C7.93021 30.6668 1.33325 24.0699 1.33325 16.0002ZM15.9999 4.00016C9.40297 4.00016 3.99992 9.40321 3.99992 16.0002C3.99992 22.5971 9.40297 28.0002 15.9999 28.0002C22.5969 28.0002 27.9999 22.5971 27.9999 16.0002C27.9999 9.40321 22.5969 4.00016 15.9999 4.00016ZM11.2838 11.284C11.8045 10.7633 12.6487 10.7633 13.1694 11.284L15.9999 14.1145L18.8304 11.284C19.3511 10.7633 20.1954 10.7633 20.7161 11.284C21.2368 11.8047 21.2368 12.6489 20.7161 13.1696L17.8855 16.0002L20.7161 18.8307C21.2368 19.3514 21.2368 20.1956 20.7161 20.7163C20.1954 21.237 19.3511 21.237 18.8304 20.7163L15.9999 17.8858L13.1694 20.7163C12.6487 21.237 11.8045 21.237 11.2838 20.7163C10.7631 20.1956 10.7631 19.3514 11.2838 18.8307L14.1143 16.0002L11.2838 13.1696C10.7631 12.6489 10.7631 11.8047 11.2838 11.284Z"
                    fill="#145D51"
                  />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Logos
