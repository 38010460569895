import React, { useEffect, useRef, useState } from 'react'

import style from './Form.module.css'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components'
import { sendInTg } from '../../Constants'
import ReactInputMask from 'react-input-mask'
import bgSecondWeb from '../../Assets/Images/Form/bgSecondWeb.webp'
import bgSecondMob from '../../Assets/Images/Form/bgSecondMob.webp'
import { useWindowSize } from '../../Hooks'

const Form = ({ form }) => {
  const isFirstForm = form === '1'
  const { width } = useWindowSize()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setPhone('')
      setIsBlurredPhone(false)
    }
    // eslint-disable-next-line
  }, [isSubmitSuccessful])

  const toastId = useRef(null)
  const onSubmit = async ({ name }) => {
    if (toastId.current) {
      toast.dismiss(toastId.current)
    }
    toastId.current = toast(<Toastify type={'pending'} />, {
      autoClose: false,
      hideProgressBar: true,
    })

    let msg = { name: name, phone: phone }
    if (isValid) {
      let resp = await sendInTg(msg)
      if (resp.ok) {
        toast.update(toastId.current, {
          render: <Toastify type={'success'} />,
          type: 'success',
          icon: false,
          autoClose: 3000,
          hideProgressBar: false,
        })
      }
      if (!resp.ok) {
        toast.update(toastId.current, {
          render:
            'Щось пішло не так. Будь ласка, оновіть сторінку та спробуйте ще раз',
          type: 'error',
          autoClose: 7000,
        })
      }
    }
  }

  const redBord = {
    borderBottom: '1px solid rgba(255,0,0, 1)',
  }
  const greenBord = isFirstForm
    ? {
        borderBottom: '1px solid rgba(159, 255, 159, 1)',
      }
    : {
        borderBottom: '1px solid #145D51',
      }

  return (
    <div
      className={style.bg}
      style={
        isFirstForm
          ? {}
          : {
              backgroundImage: `url(${
                width > 720 ? bgSecondWeb : bgSecondMob
              })`,
            }
      }
    >
      <div className={`customContainer ${style.wrap}`}>
        <div>
          <div className={style.wrap_titleWrap}>
            <h2
              className={style.wrap_titleWrap__title}
              style={isFirstForm ? {} : { color: '#145D51' }}
            >
              Зворотній зв'язок
            </h2>
            <hr
              className={style.wrap_titleWrap__line}
              style={isFirstForm ? {} : { background: '#145D51' }}
            />
          </div>
          <h5
            className={style.wrap_subtitle}
            style={isFirstForm ? {} : { color: '#145D51' }}
          >
            Залишіть свій контакт і ми зв'яжемось з вами
          </h5>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <div className={style.form_box}>
            <div
              className={style.form_box__wrap}
              style={{
                ...(errors?.name && touchedFields?.name
                  ? redBord
                  : !errors?.name && touchedFields?.name
                  ? greenBord
                  : null),
                ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
              }}
            >
              <input
                type="text"
                id={'name'}
                placeholder="Імʼя"
                className={style.form_box__wrap_inp}
                style={isFirstForm ? {} : { color: '#145D51' }}
                {...register('name', {
                  required: true,
                  minLength: 2,
                  maxLength: 30,
                })}
                autoComplete={'off'}
              />
            </div>
          </div>

          <div className={style.form_box}>
            <div
              className={style.form_box__wrap}
              style={{
                ...(!isValid && isBlurredPhone
                  ? redBord
                  : isValid && isBlurredPhone
                  ? greenBord
                  : null),
                ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
              }}
            >
              <ReactInputMask
                mask="+38 (099) 999-99-99"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={() => setIsBlurredPhone(true)}
                className={style.form_box__wrap_inp}
                placeholder={'+38 (095) 000-00-00'}
                style={isFirstForm ? {} : { color: '#145D51' }}
              />
            </div>
          </div>

          <button
            className={isFirstForm ? style.form_btn : style.form_btnSecond}
          >
            Надіслати
          </button>
        </form>
      </div>
    </div>
  )
}

export { Form }
