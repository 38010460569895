import React from 'react'
import s from './SABBuilding.module.css'
import { image } from '../../Assets/Images/SabBuilding'

const SabBuilding = () => {
  const handleWidgetOpen = () => {
    if (window.g_widget_open) {
      window.g_widget_open(25711) // Викликаємо функцію window.g_widget_open з параметром 123456
    } else {
      console.error('Функція g_widget_open не визначена.')
    }
  }
  return (
    <div className={`customContainer ${s.wrap}`} id={'sabBuilding'}>
      <div className={s.wrap_titleWrap}>
        <div className={s.wrap_titleWrap__box}>
          <hr className={s.wrap_titleWrap__box_line} />
          <h2 className={s.wrap_titleWrap__box_title}>ЖК SAB</h2>
        </div>
        <h2 className={s.wrap_titleWrap__subtitle}>Борислав</h2>
      </div>

      <div className={s.wrap_bottom}>
        <img src={image} alt="house" className={s.wrap_bottom__img} />

        <div className={s.right}>
          <p className={s.right_textWrap}>
            <span>
              Проект створений в гармонії сучасного ритму життя та максимального
              комфорту вдома. Все – поруч.
            </span>
            <span>
              Розвинена інфраструктура та можливість задовольнити всі потреби.
            </span>
            <span>
              Проект сервіс класу створений для людей та про людей. Комфортні
              планування, ергономічні рішення, зони для дозвілля дітей та
              прогулянок з улюбленцями.
            </span>
          </p>

          <button className={s.right_btn} onClick={handleWidgetOpen}>
            Обрати квартиру
          </button>
        </div>
      </div>
    </div>
  )
}

export { SabBuilding }
