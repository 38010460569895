import React from 'react'
import style from './MainBlock.module.css'
import Header from './Header/Header'
import { ReactComponent as ArrowIcon } from '../../Assets/Icons/arrowIcon.svg'
import { Link } from 'react-scroll'
import { useWindowSize } from '../../Hooks'

const MainBlock = () => {
  const { width } = useWindowSize()

  return (
    <div className={style.bg}>
      <Header />
      <div className={`customContainer ${style.wrap}`}>
        <h1 className={style.wrap_title}>
          Добре, коли <br />
          ти вдома
        </h1>
        <h5 className={style.wrap_subtitle}>
          Насолоджуйтесь перевагами життя у рідному місті: гармонія сучасного
          {width > 720 ? <br /> : ''} ритму, максимальний комфорт, розвинена
          інфраструктура.{' '}
        </h5>

        <Link
          to={'sabBuilding'}
          smooth={true}
          duration={300}
          className={style.wrap_bottom}
        >
          <span className={style.wrap_bottom__txt}>Гортай далі</span>{' '}
          <ArrowIcon />
        </Link>
      </div>
    </div>
  )
}

export { MainBlock }
