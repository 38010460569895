import React from 'react'
import style from './WebHeader.module.css'
import { Link } from 'react-scroll'
import { logo } from '../../../../Assets/Icons/icons'

const WebHeader = () => {
  const handleWidgetOpen = () => {
    if (window.g_widget_open) {
      window.g_widget_open(25711) // Викликаємо функцію window.g_widget_open з параметром 123456
    } else {
      console.error('Функція g_widget_open не визначена.')
    }
  }
  return (
    <header className={`customContainer ${style.container}`}>
      <img src={logo} alt="logo" className={style.container_logo} />

      <div className={style.container_links}>
        <Link
          to={'sabBuilding'}
          smooth={true}
          duration={300}
          className={style.container_links__link}
        >
          Про нас
        </Link>
        <Link
          to={'why'}
          smooth={true}
          duration={300}
          className={style.container_links__link}
        >
          Чому ми
        </Link>
        <Link
          to={'cases'}
          smooth={true}
          duration={300}
          className={style.container_links__link}
        >
          Проекти
        </Link>
        <Link
          to={'footer'}
          smooth={true}
          duration={300}
          className={style.container_links__link}
        >
          Контакти
        </Link>
      </div>

      <button className={style.container_btn} onClick={handleWidgetOpen}>
        Підібрати квартиру
      </button>
    </header>
  )
}

export { WebHeader }
